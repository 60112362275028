<template>
  <div>
    <div
      style="background-color: rgba(50, 61, 76, 1)"
      class="invoice_header text-white invoice-padding m-0 p-0"
    >
      <!-- Header -->
      <b-card-body class="invoice-padding mx-1 py-4">
        <div class="d-flex justify-content-between invoice-spacing mt-0">
          <!-- Header: Left Content -->
          <div style="display: flex; align-items: center">
            <div class="logo-wrapper">
              <logo />
            </div>
          </div>

          <!-- Header: Right Content -->
          <div class="mt-md-0 mt-2">
            <h4 class="invoice-numbercs mb-2 text-white" style="text-align: end">
              Estimate #{{ quote.tfr.tfr }}
            </h4>
            <p class="card-text mb-25 mt-2 base-paragraph">Clearspace Offices Inc.</p>
            <p class="card-text mb-25 base-paragraph">
              {{
                quote.geography != "Montréal" ? "901-20 Victoria Street" : montrealAddress
              }}
            </p>
            <p class="card-text mb-0 base-paragraph">
              {{
                quote.geography != "Montréal"
                  ? "Toronto, ON, Canada M5C 2N8"
                  : "Montréal, QC, Canada H3B 2N2"
              }}
            </p>
            <p class="card-text mb-0 base-paragraph" v-if="quote.geography == 'Montréal'">
              {{ $t("quote.quebecEnterpriseNumber", "en") }}
            </p>
            <p class="card-text mb-0 base-paragraph" v-if="quote.geography == 'Montréal'">
              {{ $t("quote.RbqLicense", "en") }}
            </p>
          </div>
        </div>
      </b-card-body>
    </div>
    <!-- Invoice Client & Payment Details -->
    <b-card-body class="invoice-padding mt-3 pt-0">
      <b-row class="invoice-spacing mx-1">
        <!-- Col: Invoice To -->
        <b-col class="pl-0" cols="9">
          <h6 class="header-label text-muted">
            {{ $t("quote.address", "en") }}
          </h6>
          <h1
            class="card-text mb-25"
            style="
              font-weight: 500 !important;
              font-size: 28px !important;
              line-height: 28px !important;
              color: #636363 !important;
              text-transform: uppercase;
            "
          >
            {{ quote.address }}
          </h1>
          <h2
            class="card-text mb-25"
            style="
              font-weight: 500 !important;
              font-size: 28px !important;
              line-height: 28px !important;
              color: #636363 !important;
              text-transform: uppercase;
            "
          >
            {{ quote.geography }}
            <!-- {{quote.project.city + ', ' + quote.project.state + ', Canada ' + quote.project.postcode}} -->
          </h2>
          <h6 class="mt-2 header-label text-muted">Site SQFT</h6>
          <p class="card-text mb-2">
            {{
              Number(quote.sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 0,
              }) +
              " " +
              $t("quote.sqft", "en")
            }}
          </p>
        </b-col>
        <!-- <b-col
                class=""
                cols="4"
              >
                <h6 class="mb-1 header-label">
                  {{$t('quote.billTo', quote.language)}}
                </h6>
                <p class="card-text mb-25">
                  {{ quote.opportunity.account_name }}
                </p>
                <p class="card-text mb-25">
                  {{ quote.opportunity.contact_name }}
                </p>
                <p class="card-text mb-25">
                  {{ quote.opportunity.contact_phone }}
                </p>
                <p class="card-text mb-0">
                  {{ quote.opportunity.contact_email }}
                </p>
              </b-col> -->

        <!-- Col: Payment Details -->
        <b-col class="mt-xl-0 mt-2" cols="3">
          <div class="w-100 text-end justify-content-xl-end">
            <div class="">
              <div class="row">
                <div class="col-12">
                  <p class="header-label text-muted pb-0 mb-0">
                    {{ $t("quote.issueDate", "en") }}
                  </p>
                  <p class="invoice-date pt-0 mt-0" style="font-size: 14px !important">
                    {{ quote.issue_date }}
                  </p>
                </div>
              </div>
            </div>
            <div class="">
              <div class="row">
                <div class="col-12">
                  <p class="header-label text-muted pb-0 mb-0">
                    {{ $t("quote.expiryDate", "en") }}:
                  </p>
                  <p class="invoice-date pt-0 mt-0">
                    {{ new Date(quote.expiration_date).toLocaleDateString("en-CA") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <h3 style="font-weight: 600; margin-top: 100px">Estimate Summary</h3>
    </b-card-body>
    <b-table-simple
      caption-top
      responsive
      class="table table-borderless"
      style="padding-right: 28px; padding-left: 28px; overflow-y: hidden; border: 0px"
    >
      <b-thead>
        <b-tr style="border: 0px">
          <b-th class="table-background-color" colspan="10"> SERVICES </b-th>
          <b-th class="table-background-color text-right" colspan="3">
            {{ $t("quote.pricePerSqft", "en") }}
          </b-th>
          <b-th class="table-background-color text-right" colspan="2">
            {{ $t("quote.subtotal", "en") }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr class="border border-0 table_sub_header">
          <b-td class="row-label" colspan="10">
            <div class="text_summary"><b>Hard Construction</b></div>
          </b-td>
          <b-td class="row-label text-right" colspan="3" style="vertical-align: top"
            >{{
              Number(
                (Number(bCtrSubtotal) + Number(cmFeeCalcUpdated)+ Number(ctrSubtotal) + Number(allowSubtotal)) /
                  quote.sqft
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td class="text-right row-label" colspan="2" style="vertical-align: top"
            >{{
              Number(
                Number(bCtrSubtotal) + Number(cmFeeCalcUpdated) + Number(ctrSubtotal) + Number(allowSubtotal)
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
        </b-tr>
        <b-tr
          class="border-b"
          :class="{ table_border_base: ctrItems.length > 0 || allowItems.length > 0 }"
        >
          <b-td class="row-label" colspan="10">
            <div class="ml-2 text_summary">Base Contract</div>
          </b-td>
          <b-td class="text-right" colspan="3" style="vertical-align: top"
            >{{
              Number(bCtrSubtotal / quote.sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td class="text-right" colspan="2" style="vertical-align: top"
            >{{
              Number(bCtrSubtotal).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
        </b-tr>

        <b-tr class="border-bottom" v-if="ctrItems.length > 0">
          <b-td class="row-label" colspan="10">
            <div class="ml-2 text_summary">Base Contract Additions</div>
          </b-td>
          <b-td class="text-right" colspan="3" style="vertical-align: top"
            >{{
              Number(ctrSubtotal / quote.sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td class=" text-right" colspan="2" style="vertical-align: top"
            >{{
              Number(ctrSubtotal).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
        </b-tr>
        <b-tr class="border-bottom" v-if="allowItems.length > 0">
          <b-td class="row-label" colspan="10">
            <div class="ml-2 text_summary">Cash Allowances</div>
          </b-td>
          <b-td class="text-right" colspan="3" style="vertical-align: top"
            >{{
              Number(allowSubtotal / quote.sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td class="text-right" colspan="2" style="vertical-align: top"
            >{{
              Number(allowSubtotal).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
        </b-tr>
        <b-tr class="border-b" v-if="quote.base_spec_version >= 4.2 && quote.cm_fee_enabled">
          <b-td colspan="10">
            <div class="ml-2 text_summary">Construction Management Fee</div>
          </b-td>

          <b-td colspan="2" class="text-right" >{{
              Number(cmFeeCalcUpdated / quote.sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}</b-td>
          <b-td
              class="text-right"
              colspan="3"
              style="vertical-align: top"
          >{{
              Number(cmFeeCalcUpdated).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
        </b-tr>
      </b-tbody>
      <b-tbody
        v-show="
          quote.ffe_toggle ||
          ffeItems.length > 0 ||
          allowFfeItems.length > 0 ||
          isBaseContractAddition
        "
      >
        <b-tr class="border-b table_sub_header">
          <b-td class="row-label" colspan="10">
            <div class="text_summary"><b>Furniture</b></div>
          </b-td>
          <b-td class="row-label text-right" colspan="3" style="vertical-align: top"
            >{{
              Number(
                (quote.ffe_toggle ? ffe_calc / quote.sqft : 0) +
                  (ffeSubtotal + Number(allowFfeSubtotal) + ffeBcSubtotal) / quote.sqft
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td class="text-right row-label" colspan="2" style="vertical-align: top"
            >{{
              Number(
                (quote.ffe_toggle ? ffe_calc : 0) +
                  ffeSubtotal +
                  Number(allowFfeSubtotal) +
                  ffeBcSubtotal
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
        </b-tr>
        <b-tr
          class="border-b"
          :class="{ table_border_base: ffeItems.length > 0 || allowFfeItems.length > 0 }"
          v-if="
            (quote.ffe_spec_quote.length > 0 && quote.ffe_toggle) ||
            isBaseContractAddition
          "
        >
          <b-td class="row-label" colspan="10">
            <div class="ml-2 text_summary">Base Contract</div>
          </b-td>
          <b-td class="text-right" colspan="3" style="vertical-align: top"
            >{{
              Number(
                (quote.ffe_toggle ? ffe_calc / quote.sqft : 0) +
                  ffeBcSubtotal / quote.sqft
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td class="text-right" colspan="2" style="vertical-align: top"
            >{{
              Number((quote.ffe_toggle ? ffe_calc : 0) + ffeBcSubtotal).toLocaleString(
                "en-CA",
                {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "CAD",
                }
              )
            }}
          </b-td>
        </b-tr>

        <b-tr class="border-bottom" v-if="ffeItems.length > 0">
          <b-td class="row-label" colspan="10">
            <div class="ml-2 text_summary">Base Contract Additions</div>
          </b-td>
          <b-td class="text-right" colspan="3" style="vertical-align: top"
            >{{
              Number(ffeSubtotal / quote.sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td class="text-right" colspan="2" style="vertical-align: top"
            >{{
              Number(ffeSubtotal).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
        </b-tr>
        <b-tr class="border-bottom" v-if="allowFfeItems.length > 0">
          <b-td class="row-label" colspan="10">
            <div class="ml-2 text_summary">Cash Allowances</div>
          </b-td>
          <b-td class="text-right" colspan="3" style="vertical-align: top"
            >{{
              Number(allowFfeSubtotal / quote.sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td class="text-right" colspan="2" style="vertical-align: top"
            >{{
              Number(allowFfeSubtotal).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
        </b-tr>
      </b-tbody>
      <b-tbody
        v-show="
          (quote.av_toggle ||
          quote.it_toggle ||
          avitItems.length > 0 ||
          allowAvItems.length > 0) &&
          quote.base_spec_version>=3.3
        "
      >
        <b-tr class="border-b table_sub_header">
          <b-td class="row-label" colspan="10">
            <div class="text_summary"><b>Audio Visual & Networking</b></div>
          </b-td>
          <b-td class="row-label text-right" colspan="3" style="vertical-align: top"
            >{{
              Number(
                (avitGlobalSubTotal() + Number(avitSubtotal) + Number(allowAvSubtotal)) /
                  quote.sqft
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td class="text-right row-label" colspan="2" style="vertical-align: top"
            >{{
              Number(
                avitGlobalSubTotal() + Number(avitSubtotal) + Number(allowAvSubtotal)
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
        </b-tr>
        <b-tr
          class="border-b"
          :class="{ table_border_base: avitItems.length > 0 || allowAvItems.length > 0 }"
          v-if="quote.av_toggle || quote.it_toggle"
        >
          <b-td class="row-label" colspan="10">
            <div class="ml-2 text_summary">Base Contract</div>
          </b-td>
          <b-td class="text-right" colspan="3" style="vertical-align: top"
            >{{
              Number(avitGlobalSubTotal() / quote.sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td class="text-right" colspan="2" style="vertical-align: top"
            >{{
              Number(avitGlobalSubTotal()).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
        </b-tr>

        <b-tr class="border-bottom" v-if="avitItems.length > 0">
          <b-td class="row-label" colspan="10">
            <div class="ml-2 text_summary">Base Contract Additions</div>
          </b-td>
          <b-td class="text-right" colspan="3" style="vertical-align: top"
            >{{
              Number(avitSubtotal / quote.sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td class="text-right" colspan="2" style="vertical-align: top"
            >{{
              Number(avitSubtotal).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
        </b-tr>
        <b-tr class="border-bottom" v-if="allowAvItems.length > 0">
          <b-td class="row-label" colspan="10">
            <div class="ml-2 text_summary">Cash Allowances</div>
          </b-td>
          <b-td class="text-right" colspan="3" style="vertical-align: top"
            >{{
              Number(allowAvSubtotal / quote.sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td class="text-right" colspan="2" style="vertical-align: top"
            >{{
              Number(allowAvSubtotal).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
        </b-tr>
      </b-tbody>
      <b-tbody
        v-show="
          (quote.avit_toggle ||
          avitItems.length > 0 ||
          allowAvItems.length > 0) &&
          quote.base_spec_version <3.3
        "
      >
        <b-tr class="border-b table_sub_header">
          <b-td class="row-label" colspan="10">
            <div class="text_summary"><b>Audio Visual & Networking</b></div>
          </b-td>
          <b-td class="row-label text-right" colspan="3" style="vertical-align: top"
            >{{
              Number(
                (avitGlobalSubTotal() + Number(avitSubtotal) + Number(allowAvSubtotal)) /
                  quote.sqft
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td class="text-right row-label" colspan="2" style="vertical-align: top"
            >{{
              Number(
                avitGlobalSubTotal() + Number(avitSubtotal) + Number(allowAvSubtotal)
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
        </b-tr>
        <b-tr
          class="border-b"
          :class="{ table_border_base: avitItems.length > 0 || allowAvItems.length > 0 }"
          v-if="quote.av_toggle || quote.it_toggle"
        >
          <b-td class="row-label" colspan="10">
            <div class="ml-2 text_summary">Base Contract</div>
          </b-td>
          <b-td class="text-right" colspan="3" style="vertical-align: top"
            >{{
              Number(avitGlobalSubTotal() / quote.sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td class="text-right" colspan="2" style="vertical-align: top"
            >{{
              Number(avitGlobalSubTotal()).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
        </b-tr>

        <b-tr class="border-bottom" v-if="avitItems.length > 0">
          <b-td class="row-label" colspan="10">
            <div class="ml-2 text_summary">Base Contract Additions</div>
          </b-td>
          <b-td class="text-right" colspan="3" style="vertical-align: top"
            >{{
              Number(avitSubtotal / quote.sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td class="text-right" colspan="2" style="vertical-align: top"
            >{{
              Number(avitSubtotal).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
        </b-tr>
        <b-tr class="border-bottom" v-if="allowAvItems.length > 0">
          <b-td class="row-label" colspan="10">
            <div class="ml-2 text_summary">Cash Allowances</div>
          </b-td>
          <b-td class="text-right" colspan="3" style="vertical-align: top"
            >{{
              Number(allowAvSubtotal / quote.sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td class="text-right" colspan="2" style="vertical-align: top"
            >{{
              Number(allowAvSubtotal).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
        </b-tr>
      </b-tbody>
      <b-tbody>
        <b-tr class="border-b table_sub_header">
          <b-td class="row-label" colspan="10">
            <div class="text_summary"><b>Soft Costs</b></div>
          </b-td>
          <b-td class="row-label text-right" colspan="3" style="vertical-align: top"
            >{{
              Number(
                quote.construction_pm_buffer * 1 +
                  quote.design_ame_buffer * 1 +
                  Number(quote.engineering_fee_price) +
                  Number(Number(quote.permit_fees) / Number(quote.sqft)) +
                  (Number(allowScSubtotal) + Number(scSubtotal)) / quote.sqft
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td class="text-right row-label" colspan="2" style="vertical-align: top"
            >{{
              Number(
                (quote.construction_pm_buffer * 1 +
                  quote.design_ame_buffer * 1 +
                  Number(quote.engineering_fee_price)) *
                  quote.sqft +
                  quote.permit_fees +
                  Number(allowScSubtotal) +
                  Number(scSubtotal)
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
        </b-tr>
        <b-tr
          class="border-b"
          :class="{ table_border_base: scItems.length > 0 || allowScItems.length > 0 }"
        >
          <b-td class="row-label" colspan="10">
            <div class="ml-2 text_summary">Base Contract</div>
          </b-td>
          <b-td class="text-right" colspan="3" style="vertical-align: top"
            >{{
              Number(
                quote.construction_pm_buffer * 1 +
                  quote.design_ame_buffer * 1 +
                  Number(quote.engineering_fee_price) +
                  +Number(Number(quote.permit_fees) / Number(quote.sqft))
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td class="text-right" colspan="2" style="vertical-align: top"
            >{{
              Number(
                (quote.construction_pm_buffer * 1 +
                  quote.design_ame_buffer * 1 +
                  Number(quote.engineering_fee_price)) *
                  quote.sqft +
                  quote.permit_fees
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
        </b-tr>

        <b-tr class="border-b" v-if="scItems.length > 0">
          <b-td class="row-label" colspan="10">
            <div class="ml-2 text_summary">Base Contract Additions</div>
          </b-td>
          <b-td class="text-right" colspan="3" style="vertical-align: top"
            >{{
              Number(scSubtotal / quote.sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td class="text-right" colspan="2" style="vertical-align: top"
            >{{
              Number(scSubtotal).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
        </b-tr>
        <b-tr class="border-b" v-if="allowScItems.length > 0">
          <b-td class="row-label" colspan="10">
            <div class="ml-2 text_summary">Cash Allowances</div>
          </b-td>
          <b-td class="text-right" colspan="3" style="vertical-align: top"
            >{{
              Number(allowScSubtotal / quote.sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td class="text-right" colspan="2" style="vertical-align: top"
            >{{
              Number(allowScSubtotal).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
        </b-tr>
      </b-tbody>
      <b-tfoot>
        <!--  v-if="
                        Number(Math.abs(quote.site_conditions_price_per_sqft)) <= 0 &&
                        Number(Math.abs(quote.options_price_per_sqft)) <= 0
                      " -->
        <b-tr class="border-footer">
          <b-td
            class="row-label project-total-label"
            colspan="10"
            style="border-top: none"
          >
            Estimated Project Total
          </b-td>
          <b-td class="row-label project-total-number text-right" colspan="3"
            >{{
              Number(estimateContractTotal() / quote.sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td colspan="2" class="row-label project-total-number text-right"
            >{{
              Number(estimateContractTotal()).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
        </b-tr>
      </b-tfoot>
    </b-table-simple>
  </div>
</template>

<script>
import {
  BCardBody,
  BCol,
  BRow,
  BTableSimple,
  BTbody,
  BTd,
  BTfoot,
  BTh,
  BThead,
  BTr,
} from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
export default {
  props: {
    quote: {
      type: Object,
      default: [],
    },
    montrealAddress: {
      type: String,
      default: [],
    },
    siteConditionsConstructionSubtotal: {
      type: Number,
      default: 0,
    },

    optionsConstructionSubtotal: {
      type: Number,
      default: 0,
    },
    optionsFfeSubtotal: {
      type: Number,
      default: 0,
    },
    optionsAvItSubtotal: {
      type: Number,
      default: 0,
    },
    showOptions: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    BCardBody,
    BCol,
    BRow,
    BTableSimple,
    BTbody,
    BTd,
    BTfoot,
    BTh,
    BThead,
    BTr,
    Logo,
  },
  computed: {
    // hard construction funcs
    ctrSubtotal() {
      let result = this.quote.options
        .filter((c) => c.budget_group == "Construction" && !c.is_base_spec)
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    bCtrSubtotal() {
      let result = this.quote.construction_spec_quote.reduce(
        (total, obj) =>
          Number(obj.total_price) + Number(this.contingencyFeeCalc(obj)) + total,
        0
      );
      // add cm fee to global price
      if(this.quote.cm_fee_enabled && this.quote.base_spec_version<4.2){
        result += this.cmFeeCalc * 1;
      }
      result += this.addBcSubtotal;
      return result;
    },
    ctrItems() {
      let result = this.quote.options.filter(
        (c) => c.budget_group == "Construction" && !c.is_base_spec
      );
      return result;
    },

    allowItems() {
      let result = this.quote.allowances.filter((c) => c.budget_group == "Construction");
      return result;
    },
    allowSubtotal() {
      let result = this.quote.allowances
        .filter((c) => c.budget_group == "Construction")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    //FFE funcs
    allowFfeSubtotal() {
      let result = this.quote.allowances
        .filter((c) => c.budget_group == "FF&E")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    allowFfeItems() {
      let result = this.quote.allowances.filter((c) => c.budget_group == "FF&E");
      return result;
    },
    ffeSubtotal() {
      let result = this.quote.options
        .filter((c) => c.budget_group == "FF&E" && !c.is_base_spec)
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    ffeItems() {
      let result = this.quote.options.filter(
        (c) => c.budget_group == "FF&E" && !c.is_base_spec
      );
      return result;
    },
    // av it funcs
    avitItems() {
      let result = this.quote.options.filter((c) => c.budget_group == "AV/IT");
      return result;
    },
    avitSubtotal() {
      let result = this.quote.options
        .filter((c) => c.budget_group == "AV/IT")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    allowAvItems() {
      let result = this.quote.allowances.filter((c) => c.budget_group == "AV/IT");
      return result;
    },
    allowAvSubtotal() {
      let result = this.quote.allowances
        .filter((c) => c.budget_group == "AV/IT")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    // Soft cost funcs
    scSubtotal() {
      let result = this.quote.options
        .filter((c) => c.budget_group == "Soft Cost")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    scItems() {
      let result = this.quote.options.filter((c) => c.budget_group == "Soft Cost");
      return result;
    },
    allowScItems() {
      let result = this.quote.allowances.filter((c) => c.budget_group == "Soft Cost");
      return result;
    },
    allowScSubtotal() {
      let result = this.quote.allowances
        .filter((c) => c.budget_group == "Soft Cost")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    ffeBcSubtotal() {
      let result = this.quote.options
        .filter((c) => c.budget_group == "FF&E" && c.is_base_spec)
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    isBaseContractAddition() {
      let exist = false;
      let result = this.quote.options.filter(
        (c) => c.budget_group == "FF&E" && c.is_base_spec
      );
      if (result.length > 0) {
        exist = true;
      }
      return exist;
    },
    isAVITBaseContractAddition() {
      let exist = false;
      let result = this.quote.options.filter(
        (c) => c.budget_group == "AV/IT" && c.is_base_spec
      );
      if (result.length > 0) {
        exist = true;
      }
      return exist;
    },
    addBcSubtotal() {
      let result = this.quote.options
        .filter(
          (c) =>
            c.budget_group == "Construction" &&
            c.is_base_spec &&
            !c.is_spec_swap &&
            !c.is_adjustment
        )
        .reduce(
          (total, obj) =>
            Number(obj.total_price) +
            (this.quote.base_spec_version > 3.6
              ? Number(
                    parseFloat(this.contingencyFeeCalc(obj))
                )
              : 0) +
            total,
          0
        );
      result += this.quote.options
        .filter(
          (c) =>
            (c.budget_group == "Construction" && c.is_base_spec && c.is_spec_swap) ||
            c.is_adjustment
        )
        .reduce(
          (total, obj) =>
            Number(obj.total_price) + parseFloat(this.contingencyFeeCalc(obj)) + total,
          0
        );
      return result;
    },
    // add ffe calc
    ffe_calc() {
      let sum = 0;
      sum = this.quote.ffe_spec_quote.reduce(
        (total, obj) =>
          (obj.specification_swap == null
            ? Number(obj.total_price)
            : obj.specification_swap.total_price * 1 +
              obj.specification_swap.total_delivery_install * 1) + total,
        0
      );
      return sum;
    },
    // change logic to calculate cmfee calc
    // ticket https://www.notion.so/clearspace1/Contingency-CM-Fee-Formulas-302caff0f00b4c0d999e65f43f025d4b
    baseSpecItemsSubTotal() {
      var sum = 0;
      // add validation for cm fee and cstrn margin per item
      this.quote.construction_spec_quote.forEach((e) => {
        sum += parseFloat(e.total_price) + parseFloat(this.contingencyFeeCalc(e));
      });

      this.quote.options
        .filter((n) => n.is_base_spec == true && n.budget_group == "Construction")
        .forEach((e) => {
          sum += parseFloat(e.total_price) + parseFloat(this.contingencyFeeCalc(e));
        });

      this.quote.site_conditions
        .filter((n) => n.is_base_spec == true && n.budget_group == "Construction")
        .forEach((e) => {
          sum += parseFloat(e.total_price);
        });
      return Number(sum).toFixed(2);
    },
    // cm fee calc
    cmFeeCalc() {
      let result = 0;
      if (this.quote.base_spec_version > 3.6 && this.quote.base_spec_version <=4.1 ) {
        result = this.quote.cm_fee_enabled
          ? Number(this.baseSpecItemsSubTotal) *
            (Number(this.quote.cm_fee_percentage) / 100)
          : 0;
      }
      return result;
    },
    //function to apply cm fee globally for quotes 4.2 and above
    cmFeeCalcUpdated() {
      let result = 0;
      const { base_spec_version, cm_fee_enabled, cm_fee_percentage, construction_spec_quote, options, allowances } = this.quote;
      const cmFeeMultiplier = Number(cm_fee_percentage) / 100;

      if (base_spec_version >= 4.2 && cm_fee_enabled) {
        // Calculate for construction_spec_quote
        result += construction_spec_quote.reduce(
            (total, o) => total + ((o.total_price*1 + this.contingencyFeeCalc(o))),
            0
        )* cmFeeMultiplier;
        // Calculate for options with budget_group 'Construction'
        result += options
            .filter(x => x.budget_group === 'Construction' && x.is_base_spec)
            .reduce((total, o) => total + ((o.total_price*1 + this.contingencyFeeCalc(o)*1)), 0)* cmFeeMultiplier;
        result += options
            .filter(x => x.budget_group === 'Construction' && !x.is_base_spec)
            .reduce((total, o) => total + ((o.total_price*1 )), 0)* cmFeeMultiplier;

        // Calculate for allowances with budget_group 'Construction'
        result += allowances
            .filter(x => x.budget_group === 'Construction')
            .reduce((total, o) => total + ((o.total_price*1 )), 0)* cmFeeMultiplier;
      }
      return result;
    },
  },
  data() {
    return {
      excluded_codes_contingency_fee: [
        "Z1070.30.01",
        "Z1070.30.02",
        "Z1070.30.03",
        "Z1070.40",
        "Z1070.50",
        "Z1070.60",
        "Z1070.80",
        "Z1050.20",
        "Z1050.25",
        "Z1050.30",
        "Z1020.10",
      ],
      excluded_codes_contingency_fee_v2:
          [
            "Z1070.30.03",
            "Z1070.60",
            "Z1020.10",
            "Z1020.10",
            "Z1070.30.02",
            "Z1070.30.02",
            "Z1070.60",
            "Z1050.35",
            "Z1070.20",
            "Z1010.10"
          ],
    };
  },
  methods: {
    baseContractSqft() {
      let HC =
        this.quote.construction_cost_per_sqft +
        this.quote.construction_buffer * 1 +
        this.quote.site_conditions_construction_price_per_sqft +
        this.quote.options_construction_price_per_sqft;
      let FFE =
        (!this.quote.ffe_toggle ? 0 : this.quote.ffe_price_per_sqft) +
        this.quote.options_ffe_price_per_sqft;
      let AVIT =
        this.avitGlobalSubTotal() / this.quote.sqft +
        this.quote.options_avit_price_per_sqft;
      let SC =
        this.quote.construction_pm_buffer * 1 +
        this.quote.design_ame_buffer * 1 +
        Number(this.quote.engineering_fee_price) +
        this.quote.permit_fees_cost_per_sqft;
      return HC + FFE + AVIT + SC;
    },
    baseContractTotal() {
      let HC = this.bCtrSubtotal;
      let FFE = (!this.quote.ffe_toggle ? 0 : this.ffe_calc) + this.optionsFfeSubtotal;
      let AVIT = this.avitGlobalSubTotal() + this.optionsAvItSubtotal;
      let SC =
        (this.quote.construction_pm_buffer * 1 +
          this.quote.design_ame_buffer * 1 +
          Number(this.quote.engineering_fee_price)) *
          this.quote.sqft +
        this.quote.permit_fees;
      return HC + FFE + AVIT + SC;
    },
    estimateContractTotal() {
      let HC =
        Number(this.bCtrSubtotal) + Number(this.cmFeeCalcUpdated) + Number(this.ctrSubtotal) + Number(this.allowSubtotal);
      let FFE =
        (!this.quote.ffe_toggle ? 0 : this.ffe_calc) +
        this.ffeSubtotal +
        Number(this.allowFfeSubtotal) +
        this.ffeBcSubtotal;
      let AVIT =
        this.avitGlobalSubTotal() +
        Number(this.avitSubtotal) +
        Number(this.allowAvSubtotal);
      let SC =
        (this.quote.construction_pm_buffer * 1 +
          this.quote.design_ame_buffer * 1 +
          Number(this.quote.engineering_fee_price)) *
          this.quote.sqft +
        this.quote.permit_fees +
        Number(this.allowScSubtotal) +
        Number(this.scSubtotal);
      return HC + FFE + AVIT + SC;
    },
    baseContractAdditionsSqft() {
      let SCond = 0;
      let ASU = 0;
      if (this.showSiteConditions) {
        SCond = this.quote.site_conditions_price_per_sqft;
      }
      if (this.showOptions) {
        ASU = this.quote.options_price_per_sqft;
      }
      return SCond + ASU;
    },
    baseContractAdditionsTotal() {
      let SCond = 0;
      let ASU = 0;
      if (this.showSiteConditions) {
        SCond = this.quote.site_conditions_total_price;
      }
      if (this.showOptions) {
        ASU = this.quote.options_total_price;
      }
      return SCond + ASU;
    },
    avitTotals(option) {
      let total = 0;
      total = this.quote.avit_spec_quote
        .filter((x) => x.subcategory == option)
        .reduce(
          (total, obj) =>
            (obj.specification_swap == null
              ? Number(obj.supply_price * obj.qty)
              : obj.specification_swap.total_price * 1) + total,
          0
        );
      return total;
    },
    avitDI(option) {
      let total = 0;
      total = this.quote.avit_spec_quote
        .filter((x) => x.subcategory == option)
        .reduce(
          (total, obj) =>
            (obj.specification_swap == null
              ? Number(obj.unit_delivery_install * obj.qty)
              : Number(obj.specification_swap.total_delivery_install * 1)) + total,
          0
        );
      return total;
    },
    avitGlobalSubTotal() {
      let sum = 0;
      if (this.quote.base_spec_version < 3.3) {
        sum += this.quote.avit_toggle ? this.avitTotals("IT") : 0;
        sum += this.quote.avit_toggle ? this.avitTotals("AV") : 0;
        sum += this.quote.avit_toggle ? this.avitDI("IT") : 0;
        sum += this.quote.avit_toggle ? this.avitDI("AV") : 0;
      } else {
        sum += this.quote.it_toggle ? this.avitTotals("IT") : 0;
        sum += this.quote.av_toggle ? this.avitTotals("AV") : 0;
        sum += this.quote.it_toggle ? this.avitDI("IT") : 0;
        sum += this.quote.av_toggle ? this.avitDI("AV") : 0;
      }
      return sum;
    },

    // calculate base spec adjustment qty
    constructionAdjustmentTotalQuantity(constructionWorkId) {
      var sum = 0;
      this.quote.options
        .filter((n) => n.construction_work_id == constructionWorkId)
        .forEach((e) => {
          sum += parseFloat(e.quantity);
        });
      return parseFloat(sum);
    },
    contingencyFeeCalc(construction_spec) {
      let result =
        this.quote.base_spec_version > 3.6
          ? Number(
              Number(construction_spec.total_cost) *
                (this.quote.contingency_fee_percentage / 100)
            )
          : 0;
      if (
          this.excluded_codes_contingency_fee.filter(
              (e) => e == construction_spec.uniformat
          ).length > 0 && this.quote.base_spec_version > 3.6 && this.quote.base_spec_version <=4.1
      ) {
        result = 0;
      }
      if (
          this.excluded_codes_contingency_fee_v2.filter(
              (e) => e == construction_spec.uniformat
          ).length > 0 && this.quote.base_spec_version >= 4.2
      ) {
        result = 0;
      }
      // create custom adjustment for elements that qty is adjusted to 0
      // if (
      // (  construction_spec.qty +
      //     this.constructionAdjustmentTotalQuantity(
      //       construction_spec.construction_work_id
      //     ) )==
      //   0
      // ) {
      //   result = 0;
      // }
      // let cstrn_iten_filter = this.quote.construction_spec_quote.find((c)=>c.construction_work_id==construction_spec.construction_work_id);
      // if (cstrn_iten_filter&&
      //   cstrn_iten_filter.qty +
      //     this.constructionAdjustmentTotalQuantity(
      //       construction_spec.construction_work_id
      //     ) ==
      //   0
      // ) {
      //   result = 0;
      // }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  color:white !important;
}
</style>
