export function groupItemsByComplexScope(items, complexScopes, budget_group) {
  const scopeMap = new Map()
  // Populate the scope map
  complexScopes.filter(scope => scope.budget_group === budget_group).forEach(scope => {
    scopeMap.set(scope.id, { id: scope.id, name: scope.name, description: scope.description })
  })

  // Process items and group them
  return items
    .filter(item => item.budget_group === budget_group)
    .reduce((groups, item) => {
      const scopeId = item.complex_scope_id
      const scope = scopeMap.get(scopeId)
      const groupName = scope ? scope.name : 'Ungrouped'
      const groupDesc = scope ? scope.description : 'Ungrouped'
      const existingGroup = groups.find(group => group.name === groupName)

      if (existingGroup) {
        existingGroup.items.push(item)
        existingGroup.total_price += 1 * item.total_price
        existingGroup.description = groupDesc
        // Sort the items in the existing group based on their order property
        existingGroup.items.sort((a, b) => a.order - b.order)
      } else {
        groups.push({
          name: groupName,
          description: groupDesc,
          total_price: 1 * item.total_price,
          items: [item],
        })
      }
      return groups.sort((a, b) => {
          if (a.name === 'Ungrouped' && b.name !== 'Ungrouped') return 1;
          if (b.name === 'Ungrouped' && a.name !== 'Ungrouped') return -1;
          return a.name.localeCompare(b.name);
      })
    }, [])
}
