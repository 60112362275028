<template>
  <div>
    <b-card
      v-show="
        Number(quote.base_spec_version) < 3.3
          ? (quote.avit_toggle && avit_spec_quote.length > 0) ||
            avitItems.length > 0 ||
            allowItems.length > 0
          : ((quote.av_toggle || quote.it_toggle) && avit_spec_quote.length > 0) ||
            avitItems.length > 0 ||
            allowItems.length > 0
      "
      class="invoice-preview-card pagebreak"
      no-body
    >
      <b-card-body class="invoice-padding pb-0">
        <div
          class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
        >
          <!-- Header: Left Content -->
          <div />

          <!-- Header: Right Content -->
          <div class="mt-md-0 mt-2">
            <h4 class="invoice-numbercs mb-2">Estimate #{{ quote.tfr.tfr }}</h4>
          </div>
        </div>
        <h2 class="font-weight-bold">Audio Visual & Networking</h2>
        <h5 class="text-muted mt-1">Summary</h5>
        <div class="pb-4">
          <b-table-simple
            fixed
            class="mt-1 pt-0"
            style="padding-right: 28px; padding-left: 28px; overflow-y: hidden"
          >
            <b-thead>
              <b-tr>
                <b-th class="table-background-color" colspan="7"> Services</b-th>
                <b-th class="table-background-color text-right" colspan="3">
                  Price / SQFT
                </b-th>
                <b-th class="table-background-color text-right" colspan="3">
                  Subtotal
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody
              v-if="
                Number(quote.base_spec_version) < 3.3
                  ? quote.avit_toggle
                  : quote.av_toggle || quote.it_toggle
              "
            >
              <b-tr class="table_sub_header">
                <b-td colspan="7"><b>Base Contract</b></b-td>
                <b-td colspan="3" class="text-right">
                  <b>{{
                    Number(
                      quote.av_toggle || quote.it_toggle
                        ? avitGlobalSubTotal() / quote.sqft
                        : 0
                    ).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}</b></b-td
                >
                <b-td colspan="3" class="text-right"
                  ><b>{{
                    Number(
                      quote.av_toggle || quote.it_toggle ? avitGlobalSubTotal() : 0
                    ).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}</b></b-td
                >
              </b-tr>
              <b-tr
                v-if="
                  Number(quote.base_spec_version) < 3.3
                    ? quote.avit_toggle
                    : avItems.length > 0 && quote.av_toggle
                "
              >
                <template>
                  <b-td colspan="7">
                    <div class="ml-2">Audio Visual</div>
                  </b-td>
                  <b-td colspan="3" class="text-right">
                    {{
                      Number(avitTotals("AV") / quote.sqft).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </b-td>

                  <b-td colspan="3" class="text-right">
                    {{
                      Number(avitTotals("AV")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </b-td>
                </template>
              </b-tr>
              <b-tr v-if="avItems.length > 0 && quote.av_toggle">
                <template>
                  <b-td colspan="7">
                    <div class="ml-4">Delivery & Installation</div>
                  </b-td>
                  <b-td colspan="3" class="text-right">
                    {{
                      Number(avitDI("AV") / quote.sqft).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </b-td>

                  <b-td colspan="3" class="text-right">
                    {{
                      Number(avitDI("AV")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </b-td>
                </template>
              </b-tr>
              <b-tr
                v-if="
                  Number(quote.base_spec_version) < 3.3
                    ? quote.avit_toggle
                    : quote.it_toggle
                "
              >
                <template>
                  <b-td colspan="7">
                    <div class="ml-2">Networking</div>
                  </b-td>
                  <b-td colspan="3" class="text-right">
                    {{
                      Number(avitTotals("IT") / quote.sqft).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </b-td>

                  <b-td colspan="3" class="text-right">
                    {{
                      Number(avitTotals("IT")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </b-td>
                </template>
              </b-tr>
              <b-tr
                v-if="
                  Number(quote.base_spec_version) < 3.3
                    ? quote.avit_toggle
                    : quote.it_toggle
                "
              >
                <template>
                  <b-td colspan="7">
                    <div class="ml-4">Delivery & Installation</div>
                  </b-td>
                  <b-td colspan="3" class="text-right">
                    {{
                      Number(avitDI("IT") / quote.sqft).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </b-td>

                  <b-td colspan="3" class="text-right">
                    {{
                      Number(avitDI("IT")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </b-td>
                </template>
              </b-tr>
            </b-tbody>
            <template v-if="avitItems.length > 0">
              <b-tbody>
                <b-tr class="table_sub_header">
                  <b-td colspan="7"><b>Base Contract Additions </b></b-td>
                  <b-td colspan="3" class="text-right">
                    <b>{{
                        Number(avitSubtotal / quote.sqft).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}</b></b-td
                  >
                  <b-td colspan="3" class="text-right"
                  ><b>{{
                      Number(avitSubtotal).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}</b></b-td
                  >
                </b-tr>
              </b-tbody>
              <ComplexScopeAdditions
                  :quote="quote"
                  :budget-group="'AV/IT'"
                  :items="avitItems"
              />
            </template>
            <b-tbody v-if="allowItems.length > 0">
              <b-tr class="table_sub_header">
                <b-td colspan="7"><b>Cash Allowances</b></b-td>
                <b-td colspan="3" class="text-right">
                  <b>{{
                    Number(allowSubtotal / quote.sqft).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}</b></b-td
                >
                <b-td colspan="3" class="text-right"
                  ><b>{{
                    Number(allowSubtotal).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}</b></b-td
                >
              </b-tr>
              <b-tr v-for="(a, index) in allowItems" :key="index">
                <template>
                  <b-td colspan="7">
                    <div class="ml-2">
                      {{ a.name }}
                      <p class="text-muted">
                        {{ a.details }}
                      </p>
                    </div>
                  </b-td>
                  <b-td colspan="3" class="text-right">
                    {{
                      Number(a.total_price / quote.sqft).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </b-td>
                  <!-- test comment -->
                  <b-td colspan="3" class="text-right">
                    {{
                      Number(a.total_price).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </b-td>
                </template>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <hr
            class="invoice-spacing mt-2"
            style="border: 1px solid rgba(50, 61, 76, 1)"
          />
          <b-table-simple
            fixed
            style="padding-right: 28px; padding-left: 28px; overflow-y: hidden"
            table-class="text-nowrap"
          >
            <b-tfoot>
              <b-tr>
                <b-td
                  class="row-label project-total-label"
                  colspan="7"
                  style="border-top: none"
                >
                  <h4 style="font-weight: 600">Audio Visual & Networking Subtotal</h4>
                </b-td>
                <b-td
                  class="row-label project-total-number text-right"
                  colspan="3"
                  style="border-top: none"
                >
                  {{
                    Number(
                      ((quote.avit_toggle ? avitGlobalSubTotal() : 0) +
                        avitSubtotal +
                        Number(allowSubtotal)) /
                        quote.sqft
                    ).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </b-td>
                <b-td
                  class="project-total-number text-right"
                  colspan="3"
                  style="border-top: none"
                >
                  {{
                    Number(
                      (quote.avit_toggle ? avitGlobalSubTotal() : 0) +
                        avitSubtotal +
                        Number(allowSubtotal)
                    ).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </b-td>
              </b-tr>
            </b-tfoot>
          </b-table-simple>
        </div>
      </b-card-body>
      <b-card-body class="invoice-padding notprintable pt-0 footer-quote my-3">
        <span
          class="confidential-line float-right"
          v-if="itItems.length > 0 && avItems.length > 0"
          >{{ $t("quote.confidential", "en") }}</span
        >
      </b-card-body>
      <hr class="bg-primary p-0 m-0 hr_bottom" style="height: 8px" />
    </b-card>
    <b-card
      v-show="
        Number(quote.base_spec_version) < 3.3
          ? quote.avit_toggle
          : quote.av_toggle || quote.it_toggle || quote.options_avit_price_per_sqft > 0
      "
      class="invoice-preview-card pagebreak"
      no-body
    >
      <b-card-body class="invoice-padding pb-0">
        <div
          class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
        >
          <!-- Header: Left Content -->
          <div />

          <!-- Header: Right Content -->
          <div class="mt-md-0 mt-2">
            <h4 class="invoice-numbercs mb-2">Estimate #{{ quote.tfr.tfr }}</h4>
          </div>
        </div>
        <h2 class="font-weight-bold">Audio Visual & Networking</h2>
        <h5 class="text-muted mt-1">Base Contract Details</h5>
        <div class="pb-4">
          <b-table-simple
            fixed
            class="mt-1 pb-2"
            style="
              padding-right: 28px;
              padding-left: 28px;
              overflow-y: hidden;
              border-color: #1f292f0d !important;
            "
            v-if="avItems.length > 0"
          >
            <b-thead>
              <b-tr>
                <b-th class="table-background-color" colspan="4">Audio visual</b-th>
                <b-th class="table-background-color" colspan="4"> Manufacturer </b-th>
                <b-th class="table-background-color text-right" colspan="2">
                  Quantity
                </b-th>
                <b-th class="table-background-color text-right" colspan="2">
                  Price/Unit
                </b-th>
                <b-th class="table-background-color text-right" colspan="2">
                  subTotal
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(av, index) in avItems"
                :key="index"
                class="border-group-details"
              >
                <template v-if="av.qty > 0">
                  <b-td colspan="4">
                    <div>
                      {{ av.name }}
                      <div v-if="Number(quote.base_spec_version) >= 3.4">
                        <small v-if="!av.specification_swap" class="text-muted">{{
                          av.element.specification_key
                        }}</small>
                        <small v-else class="text-muted">{{
                          av.specification_swap.name
                        }}</small>
                      </div>
                    </div>
                  </b-td>
                  <b-td colspan="4">
                    <div v-if="!av.specification_swap">{{ av.element.vendor }}</div>
                    <div v-else>{{ av.specification_swap.manufacturer }}</div>
                  </b-td>

                  <b-td colspan="2" class="text-right">{{ av.qty }} </b-td>

                  <b-td colspan="2" class="text-right">
                    {{
                      Number(
                        !av.specification_swap
                          ? av.supply_price
                          : av.specification_swap.unit_cogs
                      ).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </b-td>
                  <b-td class="text-right" colspan="2">
                    {{
                      Number(
                        !av.specification_swap
                          ? av.supply_price * av.qty
                          : av.specification_swap.total_price
                      ).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </b-td>
                </template>
              </b-tr>
              <b-tr
                class="border-footer"
                style="
                  border-top: 2px solid rgba(50, 61, 76, 1);
                  border-bottom: 1px solid #1f292f0d;
                "
              >
                <b-td colspan="4" style="font-weight: 600">Delivery & Installation</b-td>
                <b-td class="table-background-color" colspan="4"> </b-td>
                <b-td class="table-background-color" colspan="2"> </b-td>
                <b-td colspan="2"> </b-td>
                <b-td colspan="2" class="text-right"
                  >{{
                    Number(avDI).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <b-table-simple
            fixed
            class="mt-3 pt-4"
            style="padding-right: 28px; padding-left: 28px; overflow-y: hidden"
            v-if="itItems.length > 0"
          >
            <b-thead>
              <b-tr>
                <b-th class="table-background-color" colspan="4">NETWORKING </b-th>
                <b-th class="table-background-color" colspan="4"> Manufacturer </b-th>
                <b-th class="table-background-color text-right" colspan="2">
                  Quantity
                </b-th>
                <b-th class="table-background-color text-right" colspan="2">
                  Price/Unit
                </b-th>
                <b-th class="table-background-color text-right" colspan="2">
                  subTotal
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(it, index) in itItems"
                :key="index"
                class="border-group-details"
              >
                <template v-if="it.qty > 0">
                  <b-td colspan="4">
                    <div>
                      {{ it.name }}
                      <div v-if="Number(quote.base_spec_version) >= 3.4">
                        <small v-if="!it.specification_swap" class="text-muted">{{
                          it.element.specification_key
                        }}</small>
                        <small v-else class="text-muted">{{
                          it.specification_swap.name
                        }}</small>
                      </div>
                    </div>
                  </b-td>
                  <b-td colspan="4">
                    <div v-if="!it.specification_swap">{{ it.element.vendor }}</div>
                    <div v-else>{{ it.specification_swap.manufacturer }}</div>
                  </b-td>

                  <b-td colspan="2" class="text-right">
                    {{ it.qty }}
                  </b-td>

                  <b-td colspan="2" class="text-right">
                    {{
                      Number(
                        !it.specification_swap
                          ? it.supply_price
                          : it.specification_swap.unit_cogs
                      ).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </b-td>
                  <b-td class="text-right" colspan="2">
                    {{
                      Number(
                        !it.specification_swap
                          ? it.supply_price * it.qty
                          : it.specification_swap.total_price
                      ).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </b-td>
                </template>
              </b-tr>
              <b-tr
                class="border-bottom"
                style="border-top: 2px solid rgba(50, 61, 76, 1)"
              >
                <b-td colspan="4" style="font-weight: 600">Delivery & Installation</b-td>
                <b-td class="table-background-color" colspan="4"> </b-td>
                <b-td class="table-background-color" colspan="2"> </b-td>
                <b-td colspan="2"> </b-td>
                <b-td colspan="2" class="text-right"
                  >{{
                    Number(itDI).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </b-card-body>
      <b-card-body class="invoice-padding notprintable pt-0 footer-quote my-3">
        <span class="confidential-line float-right">{{
          $t("quote.confidential", "en")
        }}</span>
      </b-card-body>
      <hr class="bg-primary p-0 m-0 hr_bottom" style="height: 8px" />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCol,
  BButton,
  BRow,
  BTableSimple,
  BTbody,
  BTd,
  BTfoot,
  BTh,
  BThead,
  BTr,
  VBToggle,
} from "bootstrap-vue";
import ComplexScopeAdditions from "@/views/Quotes/Components/NewView/Utils/ComplexScopeAdditions.vue";

export default {
  name: "ReviewConstructionSpecSection",
  components: {
    ComplexScopeAdditions,
    BCard,
    BCardBody,
    BCol,
    BRow,
    BTableSimple,
    BButton,
    BTr,
    BTh,
    BThead,
    BTfoot,
    BTd,
    BTbody,
  },
  props: ["quote", "optionsAvItSubtotal"],
  data() {
    return {
      avit_spec_quote: [],
    };
  },
  computed: {
    itItems() {
      if (this.quote.it_toggle) {
        return this.quote.avit_spec_quote.filter((n) => n.subcategory == "IT");
      } else {
        return [];
      }
    },
    avItems() {
      if (this.quote.av_toggle) {
        return this.quote.avit_spec_quote.filter((n) => n.subcategory == "AV");
      } else {
        return [];
      }
    },
    itDI() {
      return this.quote.avit_spec_quote
        .filter((n) => n.subcategory == "IT")
        .reduce(
          (total, obj) =>
            (obj.specification_swap == null
              ? Number(obj.unit_delivery_install * obj.qty)
              : obj.specification_swap.total_delivery_install * 1) + total,
          0
        );
    },
    avDI() {
      return this.quote.avit_spec_quote
        .filter((n) => n.subcategory == "AV")
        .reduce(
          (total, obj) =>
            (obj.specification_swap == null
              ? Number(obj.unit_delivery_install * obj.qty)
              : obj.specification_swap.total_delivery_install * 1) + total,
          0
        );
    },
    avitItems() {
      let result = this.quote.options.filter((c) => c.budget_group == "AV/IT");
      return result;
    },
    avitSubtotal() {
      let result = this.quote.options
        .filter((c) => c.budget_group == "AV/IT")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },

    allowItems() {
      let result = this.quote.allowances.filter((c) => c.budget_group == "AV/IT");
      return result;
    },
    allowSubtotal() {
      let result = this.quote.allowances
        .filter((c) => c.budget_group == "AV/IT")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
  },
  mounted() {
    this.filterAvIt();
  },
  methods: {
    avitTotals(option) {
      let total = 0;
      total = this.avit_spec_quote
        .filter((x) => x.subcategory == option)
        .reduce(
          (total, obj) =>
            (obj.specification_swap == null
              ? Number(obj.supply_price * obj.qty)
              : obj.specification_swap.total_price * 1) + total,
          0
        );
      return total;
    },
    avitDI(option) {
      let total = 0;
      total = this.avit_spec_quote
        .filter((x) => x.subcategory == option)
        .reduce(
          (total, obj) =>
            (obj.specification_swap == null
              ? Number(obj.unit_delivery_install * obj.qty)
              : Number(obj.specification_swap.total_delivery_install * 1)) + total,
          0
        );
      return total;
    },
    avitGlobalSubTotal() {
      let sum = 0;
      sum += this.avitTotals("IT");
      sum += this.avitTotals("AV");
      sum += this.avitDI("IT");
      sum += this.avitDI("AV");
      return sum;
    },
    filterAvIt() {
      if (this.quote.av_toggle && this.quote.it_toggle) {
        this.avit_spec_quote = this.quote.avit_spec_quote;
      } else if (this.quote.av_toggle) {
        this.avit_spec_quote = this.quote.avit_spec_quote.filter(
          (n) => n.subcategory == "AV"
        );
      } else if (this.quote.it_toggle) {
        this.avit_spec_quote = this.quote.avit_spec_quote.filter(
          (n) => n.subcategory == "IT"
        );
      } else {
        this.avit_spec_quote = [];
      }
    },
  },
};
</script>

<style scoped>
.disclaimer-exclusions {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ea5455;
}

.card-body {
  padding: 28px;
}
.hide-lines-review {
  display: none !important;
}
@media print {
  .pagebreak-table {
    page-break-after: always;
    white-space: nowrap;
    padding-top: 2em !important;
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  .hide-lines {
    display: none !important;
  }
  .hide-lines-review {
    display: table !important;
    padding-top: 2em !important;
  }
}
[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  color:white !important;
}
</style>
