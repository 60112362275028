<template>
  <tbody>
    <template v-if="quote.base_spec_version< 4.1">
      <b-tr
        v-for="(c, index) in items"
        :key="'ctrI' + index"
        class="border-group-details"
      >
        <b-td colspan="9">
          <div class="ml-2">
            {{ c.name }}
            <div class="text-muted">
              {{ c.details }}
            </div>
          </div>
        </b-td>
        <b-td
          :class="[c.total_price < 0 ? 'credit ' : ''] + 'row-label'"
          colspan="2"
          style="font-weight: normal"
          class="text-right"
        >
          {{
            Number(c.total_price / quote.sqft).toLocaleString("en-CA", {
              minimumFractionDigits: 2,
              style: "currency",
              currency: "CAD",
            })
          }}
        </b-td>
        <b-td
          :class="[c.total_price < 0 ? 'credit ' : '']"
          total
          cost
          colspan="2"
          style="font-weight: normal"
          class="text-right"
        >
          {{
            Number(c.total_price).toLocaleString("en-CA", {
              minimumFractionDigits: 2,
              style: "currency",
              currency: "CAD",
            })
          }}
        </b-td>
      </b-tr>
    </template>
    <template
      v-for="(g, index) in groupItemsByComplexScope"
      v-else
    >
      <b-tr
        v-if="g.name!=='Ungrouped'"
        :key="'ctrI' + index"
        class="border-group-details"
      >
        <b-td colspan="9">
          <div class="ml-2">
            {{ g.name }}
            <div class="text-muted">
              {{ g.description }}
            </div>
          </div>
        </b-td>
        <b-td
          :class="[g.total_price < 0 ? 'credit ' : ''] + 'row-label'"
          colspan="2"
          style="font-weight: normal"
          class="text-right"
        >
          {{
            Number(g.total_price / quote.sqft).toLocaleString("en-CA", {
              minimumFractionDigits: 2,
              style: "currency",
              currency: "CAD",
            })
          }}
        </b-td>
        <b-td
          :class="[g.total_price < 0 ? 'credit ' : '']"
          total
          cost
          colspan="2"
          style="font-weight: normal"
          class="text-right"
        >
          {{
            Number(g.total_price).toLocaleString("en-CA", {
              minimumFractionDigits: 2,
              style: "currency",
              currency: "CAD",
            })
          }}
        </b-td>
      </b-tr>
      <template v-else>
        <b-tr
          v-for="no_grouped,idx in g.items"
          :key="'ctrI-nogroped' + idx+''+no_grouped.id"
          class="border-group-details"
        >
          <b-td colspan="9">
            <div class="ml-2">
              {{ no_grouped.name }}
              <div class="text-muted">
                {{ no_grouped.details }}
              </div>
            </div>
          </b-td>
          <b-td
            :class="[no_grouped.total_price < 0 ? 'credit ' : ''] + 'row-label'"
            colspan="2"
            style="font-weight: normal"
            class="text-right"
          >
            {{
              Number(no_grouped.total_price / quote.sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
          <b-td
            :class="[no_grouped.total_price < 0 ? 'credit ' : '']"
            total
            cost
            colspan="2"
            style="font-weight: normal"
            class="text-right"
          >
            {{
              Number(no_grouped.total_price).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </b-td>
        </b-tr>
      </template>
    </template>
  </tbody>
</template>

<script>
import { BTd, BTr } from 'bootstrap-vue'
import { groupItemsByComplexScope } from '@/utils/complexScopesGrouping'

export default {
  components: { BTd, BTr },
  props: {
    quote: {
      type: Object,
    },
    budgetGroup: {
      type: String,
      default: 'Construction',
    },
    items: {
      type: Array,
    },
  },
  computed: {
    groupItemsByComplexScope() {
      return groupItemsByComplexScope(this.items, this.quote.complex_scopes, this.budgetGroup)
    },
  },

}
</script>

<style lang="scss" scoped>

</style>
